import React, { useEffect } from 'react'
import Nav from '../../components/layout/Nav'
import { useParams } from 'react-router-dom'
import { useGetCurrentCartQuery } from '../../services/cartApi'
import {
  useUpdateProductQuantityMutation,
  useDeleteProductToCartMutation,
} from '../../services/cartApi'
import { useDispatch, useSelector } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import AllProductInCart from '../../components/cart/AllProductInCart'
import CartSummary from '../../components/cart/CartSummary'
import _ from 'lodash'
import distance from '../../helpers/Distance'
import LocationHandler from '../../components/location/LocationHandler'
import { setCart } from '../../features/cart/cart'
import { useCreateOrderMutation } from '../../services/cartApi'
import SuccessModal from '../../components/cart/SuccessModal'
import PayOrder from '../../components/payment/PayOrder'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import { useNavigate } from 'react-router-dom'
import { BsCartX } from 'react-icons/bs'
import LocationRequired from '../../components/modal/LocationRequired'

export default function Cart() {
  const params = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))

  const { data: cart, isLoading } = useGetCurrentCartQuery(params.id, {
    refetchOnFocus: true,
  })

  const localCart = useSelector((state) => state.cart.cart)

  const [createOrder, { isLoading: isOrdering }] = useCreateOrderMutation()

  const [updateProductQuantity] = useUpdateProductQuantityMutation()
  const [deleteProductToCart] = useDeleteProductToCartMutation()

  const dropOffLocation = useSelector((state) => state.user.location)
  console.log(dropOffLocation, currentLocation)

  const [notLocation, setNotLocation] = React.useState(false)

  const [isOrdered, setIsOrdered] = React.useState(false)
  const [paying, setPaying] = React.useState(false)

  const currentUser = useSelector((state) => state.user.currentUser)

  const convertPrice = (price, currency) => {
    if (currency && currency.shortName === '$') {
      return price * 2800
    }
    return price
  }

  const groupProductsBySellerOrUser = (cart) => {
    const groupedProducts = {}
    cart?.products?.forEach((product) => {
      const user = product?.productId?.user
      const seller = product?.productId?.seller

      if (user || (seller && seller?.user)) {
        const sellerOrUser = user?._id || seller?.user
        const userOrSeller = user || seller?.user

        if (!groupedProducts[sellerOrUser]) {
          groupedProducts[sellerOrUser] = {
            products: [],
            seller: userOrSeller,
            images: [],
            prices: [],
          }
        }
        product?.status === 'not payed' &&
          groupedProducts[sellerOrUser]?.products.push(product)
        groupedProducts[sellerOrUser]?.images.push(product?.productId.images[0])
        groupedProducts[sellerOrUser]?.prices.push(
          convertPrice(
            product?.productId?.price * product?.quantity,
            product?.productId?.currency
          )
        )
        groupedProducts[sellerOrUser].shipmentFees =
          distance(
            userOrSeller?.location?.coordinates
              ? userOrSeller?.location?.coordinates
              : [15.2854934, -4.4251149],
            dropOffLocation
          ) * 1500

        groupedProducts[sellerOrUser].distance = distance(
          userOrSeller?.location?.coordinates
            ? userOrSeller?.location?.coordinates
            : [15.2854934, -4.4251149],
          dropOffLocation
        )
      }
    })
    return groupedProducts
  }

  let data = groupProductsBySellerOrUser(localCart)
  let prices = _.sum(_.flatten(_.values(_.mapValues(data, (d) => d.prices))))
  let shipmentFees = _.sum(_.values(_.mapValues(data, (d) => d.shipmentFees)))

  const handleDeleteProductToCart = async (e, userId, productId) => {
    e.preventDefault()
    try {
      await deleteProductToCart({ product: productId, user: userId })

      // Update local cart immediately
      const updatedCart = {
        ...localCart,
        products: localCart.products.filter(
          (product) => product.productId._id !== productId
        ),
      }
      dispatch(setCart(updatedCart))
      // setLocalCart(updatedCart)
    } catch (error) {
      console.error('Erreur lors de la suppression du produit:', error)
    }
  }

  const handleUpdateProductQuantity = async (userId, productId, quantity) => {
    try {
      let productData = {
        userId: userId,
        productId: productId,
        quantity: quantity,
      }
      await updateProductQuantity(productData)

      // Update local cart immediately
      const updatedCart = {
        ...localCart,
        products: localCart.products.map((product) =>
          product.productId._id === productId
            ? { ...product, quantity: quantity }
            : product
        ),
      }
      dispatch(setCart(updatedCart))
      // setLocalCart(updatedCart)
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la quantité:', error)
    }
  }

  const handleOrderCart = async () => {
    try {
      const order = {
        user: currentUser?._id,
        data: data,
        cart: localCart?._id,
        dropOffLocation,
      }

      //dispatch(createOrder)

      await createOrder(order).then(async (response) => {
        if (response?.data?.message === 'success') {
          dispatch(setCart(null))

          setPaying(false)

          window.location.reload()

          setIsOrdered(true)
        }
      })
    } catch (error) {
      console.error('Erreur lors de la commande :', error)
    }
  }

  useEffect(() => {
    dispatch(setCart(cart))
  }, [cart, dispatch])

  useEffect(() => {
    dispatch(setDropOffLocation(currentLocation))
  }, [dispatch, currentLocation])

  useEffect(() => {
    if (_.isEmpty(currentLocation) && _.isEmpty(dropOffLocation)) {
      setNotLocation(true)
    } else {
      setNotLocation(false)
    }
  }, [])

  return (
    <div>
      <Nav />
      {!isLoading ? (
        <main className="pt-32 lg:px-48 px-5">
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">Mon panier</h2>
            <div className="mt-2">
              <LocationHandler />
            </div>
          </div>
          {localCart && localCart?.products?.length > 0 ? (
            <div className="flex flex-col lg:flex-row lg:gap-8">
              <div className="lg:w-[37.5vw]">
                <CartSummary
                  cart={localCart}
                  totalPrice={prices}
                  totalShipFees={shipmentFees}
                  data={_.mapValues(data, (o) => o.products)}
                  toPay={data}
                  setPaying={setPaying}
                />
              </div>
              <div className="lg:w-[45vw]">
                <AllProductInCart
                  cart={localCart}
                  user={params?.id}
                  handleDeleteProductToCart={handleDeleteProductToCart}
                  handleUpdateProductQuantity={handleUpdateProductQuantity}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-5 justify-center items-center pt-20 w-full">
              <BsCartX className="text-6xl text-gray-400" />
              <div className="text-[20px] text-center lg:text-lg text-gray-600">
                Vous n{"'"}avez pas de produit dans votre panier
              </div>
              <button
                className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md"
                onClick={() => {
                  navigate(`/orders/me/${params.id}`)
                }}
              >
                Voir mes commandes
              </button>
            </div>
          )}

          {notLocation && (
            <LocationRequired
              openModal={notLocation}
              setOpenModal={setNotLocation}
            />
          )}

          {paying && (
            <PayOrder
              openModal={paying}
              setOpenModal={setPaying}
              toPay={data}
              cart={cart}
              setIsOrdered={setIsOrdered}
              isOrdering={isOrdering}
              handleOrderCart={handleOrderCart}
            />
          )}
          {isOrdered && (
            <SuccessModal openModal={isOrdered} setOpenModal={setIsOrdered} />
          )}
        </main>
      ) : (
        <div className="grid place-items-center pt-48 ">
          <LottieLoader animation={indexLoader} />
          <div className="mt-4 text-gray-600">
            Chargement des produits ajoutés au panier...
          </div>
        </div>
      )}
    </div>
  )
}
